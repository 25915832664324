<template>
  <div style="width: 100%" class="com-list-container">
    <el-table
      :row-style="{ height: '75px' }"
      :header-row-style="{ height: '50px' }"
      :header-cell-style="{
        fontSize: '16px',
        color: '#696B6F',
        fontFamily: 'PingFangSC-Medium, PingFang SC;',
      }"
      :data="list"
      :row-class-name="tableRowClassName"
      :border="false"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>

      <el-table-column prop="image" label="歌单封面" width="120">
        <template slot-scope="scope">
          <div style="width: 50px; height: 50px; display: flex">
            <img :src="scope.row.image" style="width: 100%" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="歌单名称">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div
              v-if="scope.row.showImg && scope.row.image"
              style="
                width: 50px;
                height: 50px;
                display: flex;
                margin-right: 10px;
              "
            >
              <img style="width: 100%" :src="scope.row.image" />
            </div>
            <div
              class="hover"
              @click="
                $router.push(
                  `/songsDetail/${scope.row.id}?name=${scope.row.name}`
                )
              "
            >
              {{ scope.row.name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="playNum" width="180" label="上架歌曲数">
      </el-table-column>

      <el-table-column prop="date" width="240" label="创建时间">
      </el-table-column>

      <el-table-column width="240" label="操作">
        <template slot-scope="scope">
          <com-button
            size="mini"
            type="text"
            @click="
              $emit('toDetail', { id: scope.row.id, name: scope.row.name })
            "
            >详情</com-button
          >
          <com-button
            size="mini"
            type="text"
            @click="$emit('remove', { id: scope.row.id })"
            >删除</com-button
          >
        </template>
      </el-table-column>
    </el-table>
    <com-page
      :total="total"
      layout="prev, pager, next"
      :page-size="pageSize"
      @change="pageChange"
    ></com-page>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import comPage from "@/components/com/com-page";
export default {
  components: {
    comButton,
    comPage,
  },
  data() {
    return {
      // currentPage: 1,
    };
  },
  computed: {
    currentList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
  props: {
    songs: {
      type: Boolean,
      default: false,
    },
    list: Array,
    pageSize: {
      type: Number,
      default: 15,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: Number,
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.list.forEach((item, index) => (item._index = index + 1));
      },
    },
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "table-bg1" : "table-bg2";
    },
    pageChange(currentPage) {
      this.$emit("currentPageChange", currentPage);
    },
  },
};
</script>
<style lang="scss">
@import "../../style/list";
.com-list-container {
  @extend .com-list;
}
</style>
