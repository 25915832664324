<template>
  <div class="personal-container">
    <app-header></app-header>
    <div class="bg"></div>
    <div class="main">
      <div class="top" v-if="userInfo">
        <div class="image">
          <img v-if="userInfo.icon" :src="userInfo.icon" />
          <img v-else :src="avatar" />
        </div>
        <div class="info">
          <div>{{ userInfo.nickname }}</div>
          <div>
            <span style="margin-right: 30px"
              ><!--              {{ userInfo.address || "未设置地区" }}-->
              地区：
              <span v-if="userInfo.address">{{ userInfo.address }}</span>
              <span v-else style="color: #a0a2a6">未设置</span>
            </span>
            <span style="margin-right: 30px"
              >性别：{{ userInfo.sex | sexFilter }}</span
            >
            <span>
              出生日期：
              <span
                v-if="userInfo.birthday && userInfo.birthday !== '0001-01-01'"
                >{{ userInfo.birthday }}</span
              >
              <span v-else style="color: #a0a2a6">未设置</span>
            </span>
          </div>
          <div>
            <span>自我介绍：</span>
            <span
              v-if="userInfo.intro"
              class="intro"
              :class="{ shrink: shrink }"
            >
              <span v-if="shrink">{{ userInfo.intro.slice(0, 20) }}</span>
              <span v-else v-html="br(userInfo.intro)"></span>
              <span
                @click="shrink = !shrink"
                v-if="userInfo.intro.length > 20"
                >{{ shrink ? "展开" : "收起" }}</span
              >
            </span>
            <span v-else class="intro">还没有自我介绍</span>
          </div>
        </div>
        <div class="up" @click="$router.push('/set')">编辑个人资料</div>
      </div>

      <com-tab
        t="我的作品"
        :buttons="['歌单', '歌曲']"
        :currentIndex.sync="currentIndex"
      ></com-tab>

      <table-my-songs
        v-loading="loading"
        v-if="currentIndex === 0 && songsList.length !== 0"
        :list="songsList"
        @toDetail="toDetail"
        @remove="removeSongs"
        :total="total"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
      ></table-my-songs>

      <table-my-single
        v-loading="loading"
        v-if="currentIndex === 1 && singleList.length !== 0"
        :list="singleList"
        :add="true"
        @remove="removeSingle"
        @up="
          $router.push(
            `/upload?up=single&id=${$event.id}&singleName=${$event.name}`
          )
        "
        @addToSongs="addToSongs"
        :total="total"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
      ></table-my-single
      ><!-- &songsName=${$event} -->

      <div
        v-if="
          (currentIndex === 0 && songsList.length === 0) ||
          (currentIndex === 1 && singleList.length === 0)
        "
        class="data-null"
      >
        <img alt="" src="../assets/null.png" />
        <div>没有相关舞曲</div>
      </div>
    </div>
    <com-mask :visible="formSelectShow">
      <form-select
        @ok="sendSongsMusic"
        v-if="formSelectShow"
        :show.sync="formSelectShow"
        :list="allSongs"
        :music="addToSongsMusicItem"
      ></form-select>
    </com-mask>

    <app-footer></app-footer>
  </div>
</template>

<script>
import comTab from "@/components/com/com-tab";
import comMask from "@/components/com/com-mask";
import formSelect from "@/components/form/form-select";
import appHeader from "@/components/app-header";
import appFooter from "@/components/app-footer";
import tableMySingle from "@/components/table/table-my-single";
import tableMySongs from "@/components/table/table-my-songs";
import { Single, Songs } from "@/class/music";
import { mapGetters } from "vuex";

export default {
  components: {
    comTab,
    appHeader,
    appFooter,
    tableMySingle,
    tableMySongs,
    formSelect,
    comMask,
  },
  data() {
    return {
      currentIndex: 0,
      singleList: [],
      songsList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      shrink: true,
      loading: false,
      formSelectShow: false,
      allSongs: [],
      // addToSongsMusicId: null,
      // addToSongsMusicUrl: null,
      addToSongsMusicItem: null,
    };
  },
  filters: {
    sexFilter(v) {
      v = v + "";
      return (v === "0" && "靓女") || (v === "1" && "靓仔") || "保密";
    },
  },
  computed: {
    ...mapGetters({
      avatar: "avatar",
    }),
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  watch: {
    currentIndex: {
      immediate: true,
      handler(v) {
        this.currentPage = 1;
        v === 0 && this.sendGetSongsList();
        v === 1 && this.sendGetSingleList();
      },
    },
    "$store.state.user.userInfo": {
      handler(v) {
        !v && this.$router.push("/");
      },
    },
  },
  methods: {
    /*
     * 删除之后判断是否为当前页的是否没有数据
     * 没有数据则 currentPage-1
     * */
    setRemoveAlterCurrentPage() {
      let { total, pageSize, currentPage } = this;
      console.log(total, pageSize, currentPage);
      if (currentPage !== 1 && (currentPage - 1) * pageSize === total - 1) {
        this.currentPage--;
      }
    },
    toDetail(e) {
      this.$router.push(`/personalSongsDetail?id=${e.id}&name=${e.name}`);
    },
    removeSingle(d) {
      this.$confirm("确认删除？").then(() => {
        this.loading = true;
        this.$api
          .removeSingle({ ids: d.id })
          .then((resolve) => {
            // console.log(resolve);
            this.loading = false;
            if (resolve.code === 200) {
              // this.$store.commit("SET_ROUTER_KEY");
              let playIndex = this.$store.state.playId
              this.$store.commit('DEL_PLAYLIST',{d,playIndex})
              this.setRemoveAlterCurrentPage();
              this.sendGetSingleList();
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    addToSongs(d) {
      this.addToSongsMusicItem = d;
      // this.addToSongsMusicId = d.id;
      // this.addToSongsMusicUrl = d.url;
      if (!this.allSongs[0]) {
        this.$api
          .songsList({
            size: 300,
            userId: this.userInfo.id,
          })
          .then((resolve) => {
            this.allSongs = resolve.data.records;
            this.formSelectShow = true;
          })
          .catch(() => (this.loading = false));
      } else {
        this.formSelectShow = true;
      }
    },
    sendSongsMusic(listId) {
      this.$api
        .saveMusicToSongs({
          musicId: this.addToSongsMusicItem.id,
          listId,
          userId:this.userInfo.id
        })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.$message.success("保存成功");
            this.formSelectShow = false;
          } else {
            this.$message.success(resolve.msg);
          }
        });
    },
    removeSongs(d) {
      this.$confirm("确认删除？").then(() => {
        this.loading = true;
        this.$api
          .removeSongs({ ids: d.id })
          .then((resolve) => {
            // console.log(resolve);
            this.loading = false;
            if (resolve.code === 200) {
              // this.$store.commit("SET_ROUTER_KEY");
              this.setRemoveAlterCurrentPage();
              this.sendGetSongsList();
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    sendGetSingleList() {
      // const loading = this.$loading();
      this.loading = true;
      this.$api
        .singleList({
          size: this.pageSize,
          current: this.currentPage,
          userId: this.userInfo.id,
        })
        .then((resolve) => {
          this.total = resolve.data.total;
          let data = resolve.data.records;
          this.singleList = data.map((item) => new Single(item));

          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    sendGetSongsList() {
      this.loading = true;
      this.$api
        .songsList({
          size: this.pageSize,
          current: this.currentPage,
          userId: this.userInfo.id,
        })
        .then((resolve) => {
          this.total = resolve.data.total;
          let data = resolve.data.records;
          this.songsList = data.map((item) => new Songs(item));
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.currentIndex === 0 && this.sendGetSongsList();
      this.currentIndex === 1 && this.sendGetSingleList();
    },
    br(v) {
      let res = v ? v.replace(/\n/g, "<br>") : "";
      return res;
    },
  },

  mounted() {
    // this.sendGetSongsList();
  },
};
</script>
<style scoped lang="scss">
@import "/src/style/css";
@import "/src/style/personal";

.main {
  margin: auto;
  width: $containerWidth;
}
.top {
  z-index: 10;
  min-height: 317px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  padding: 69px 40px 0;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 70px;
  .image {
    width: 180px;
    height: 180px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 46px;
    width: 0;
    flex-grow: 1;
    > div:nth-child(1) {
      font-width: 600;
      font-size: 32px;
      margin-bottom: 20px;
    }
    > div {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 5px 0;
    }
    > div:nth-child(3) {
      display: flex;
      align-items: flex-start;
      > span:nth-child(1) {
        flex-shrink: 0;
        height: 25px;
        line-height: 25px;
      }
      > span:nth-child(2) {
        color: #a0a2a6;
      }
      .intro {
        line-height: 25px;
        :nth-child(2) {
          display: inline-block;
          flex-shrink: 0;
          color: #c20c0c;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .shrink {
        display: flex;
        > span:nth-child(1) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          //max-width: 300px;
          height: 25px;
        }
      }
    }
  }
  .up {
    width: 132px;
    height: 42px;
    line-height: 42px;
    border-radius: 2px;
    border: 1px solid #c20c0c;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c20c0c;
    text-align: center;
    cursor: pointer;
  }
}
.data-null {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 127px 0 141px;
  color: #696b6f;
  font-size: 18px;
  letter-spacing: 1px;
  > img {
    width: 300px;
    margin-bottom: 26px;
  }
}
</style>
