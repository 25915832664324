<template>
  <!--
    layout="prev, pager, next"-->
  <div class="page-container">
    <el-pagination
      :hide-on-single-page="true"
      @current-change="currentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      background
      :layout="layout"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    layout: String,
    total: Number,
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: Number,
  },
  methods: {
    currentChange(currentPage) {
      this.$emit("change", currentPage);
    },
  },
};
</script>
<style lang="scss">
.page-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;
  .el-pager {
    > li {
      width: 47px;
      height: 47px;
      line-height: 47px;
      font-size: 16px;

      color: #a0a2a6 !important;
      background: #fff !important;
      border-radius: 2px;
    }
    > li:hover {
      color: #c20c0c !important;
    }
    > li.active {
      //background: linear-gradient(126deg, #c20c0c 0%, #d27c7c 100%) !important;

      background: #c20c0c !important;
      color: #fff !important;
    }
  }
  .btn-prev,
  .btn-next {
    width: 47px;
    height: 47px;
    line-height: 47px;
    font-size: 16px;
    color: #a0a2a6 !important;
    background: #fff !important;
    font-size: 16px;
  }
  .btn-prev:hover,
  .btn-next:hover {
    color: #c20c0c !important;
  }
}
</style>
