<template>
  <div style="width: 100%" class="com-list-container">
    <el-table
      :row-style="{ height: '75px' }"
      :header-row-style="{ height: '50px' }"
      :header-cell-style="{
        fontSize: '16px',
        color: '#696B6F',
        fontFamily: 'PingFangSC-Medium, PingFang SC;',
      }"
      :data="list"
      :row-class-name="tableRowClassName"
      :border="false"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>

      <el-table-column v-if="!songs" prop="image" label="歌曲封面" width="120">
        <template slot-scope="scope">
          <div style="width: 50px; height: 50px; display: flex">
            <img :src="scope.row.image" style="width: 100%" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="歌曲名">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div
              v-if="scope.row.showImg && scope.row.image"
              style="
                width: 50px;
                height: 50px;
                display: flex;
                margin-right: 10px;
              "
            >
              <img style="width: 100%" :src="scope.row.image" />
            </div>
            <div v-if="~~scope.row.shelves === 0">{{ scope.row.name }}</div>
            <div
              v-else
              class="hover"
              @click="$router.push(`/singleDetail/${scope.row.id}`)"
            >
              {{ scope.row.name }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="singer" width="180" label="状态">
        <template slot-scope="scope">
          <div v-if="scope.row.shelves === 1">已上架</div>
          <div v-else>未上架</div>
        </template>
      </el-table-column>

      <el-table-column prop="singer" width="180" label="歌手名">
      </el-table-column>

      <el-table-column v-if="!songs" width="240" label="发行时间">
        <template slot-scope="scope">
          {{ scope.row.date | ymd }}
        </template>
      </el-table-column>

      <el-table-column v-if="songs" prop="duration" width="240" label="时间">
        <template slot-scope="scope">
          {{ scope.row.duration | formatSeconds }}
        </template>
      </el-table-column>

      <el-table-column width="280" label="操作">
        <template slot-scope="scope">
          <com-button size="mini" type="text" @click="$emit('up', scope.row)"
            >编辑</com-button
          >
          <com-button
            size="mini"
            type="text"
            @click="$emit('remove', { id: scope.row.id })"
            >删除</com-button
          >
          <com-button
            v-if="add"
            size="mini"
            type="text"
            @click="$emit('addToSongs', scope.row)"
            >添加到歌单</com-button
          >
          <com-button
            v-if="out"
            size="mini"
            type="text"
            @click="$emit('shiftOutSongs', { id: scope.row.id })"
            >移出此歌单</com-button
          >
        </template>
      </el-table-column>
    </el-table>

    <com-page
      :total="total"
      layout="prev, pager, next"
      :page-size="pageSize"
      @change="pageChange"
    ></com-page>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import comPage from "@/components/com/com-page";
import { formatSeconds } from "@/mixin/date";
export default {
  mixins: [formatSeconds],
  components: {
    comButton,
    comPage,
  },
  data() {
    return {
      // currentPage: 1,
    };
  },
  computed: {
    currentList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
  props: {
    songs: {
      type: Boolean,
      default: false,
    },
    list: Array,
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: Number,
    add: {
      type: Boolean,
      default: false,
    },
    out: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.list.forEach((item, index) => (item._index = index + 1));
      },
    },
  },
  filters: {
    ymd(v) {
      return v.split(" ")[0];
    },
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "table-bg1" : "table-bg2";
    },
    pageChange(currentPage) {
      this.$emit("currentPageChange", currentPage);
    },
  },
};
</script>
<style lang="scss">
@import "../../style/list";
.com-list-container {
  @extend .com-list;
}
</style>
