var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"com-list-container",staticStyle:{"width":"100%"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"row-style":{ height: '75px' },"header-row-style":{ height: '50px' },"header-cell-style":{
      fontSize: '16px',
      color: '#696B6F',
      fontFamily: 'PingFangSC-Medium, PingFang SC;',
    },"data":_vm.list,"row-class-name":_vm.tableRowClassName,"border":false}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"prop":"image","label":"歌单封面","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"width":"50px","height":"50px","display":"flex"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":scope.row.image}})])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"歌单名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(scope.row.showImg && scope.row.image)?_c('div',{staticStyle:{"width":"50px","height":"50px","display":"flex","margin-right":"10px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":scope.row.image}})]):_vm._e(),_c('div',{staticClass:"hover",on:{"click":function($event){return _vm.$router.push(
                `/songsDetail/${scope.row.id}?name=${scope.row.name}`
              )}}},[_vm._v(" "+_vm._s(scope.row.name)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"playNum","width":"180","label":"上架歌曲数"}}),_c('el-table-column',{attrs:{"prop":"date","width":"240","label":"创建时间"}}),_c('el-table-column',{attrs:{"width":"240","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('com-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.$emit('toDetail', { id: scope.row.id, name: scope.row.name })}}},[_vm._v("详情")]),_c('com-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.$emit('remove', { id: scope.row.id })}}},[_vm._v("删除")])]}}])})],1),_c('com-page',{attrs:{"total":_vm.total,"layout":"prev, pager, next","page-size":_vm.pageSize},on:{"change":_vm.pageChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }