<template>
  <div class="select-container">
    <h4>选择歌单</h4>
    <div class="info">
      <div class="image"><img :src="music && music.image" /></div>
      <div class="name">{{ music && music.name }}</div>
    </div>
    <el-select
      popper-class="diy-popper"
      class="diy-select"
      v-model="songs"
      placeholder="请选择歌单"
      style="width: 100%"
    >
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.name"
        :value="item.id"
        >{{ item.name }}</el-option
      >
    </el-select>
    <div class="btns">
      <com-button @click="ok">确定</com-button>
      <com-button type="shallow" @click="$emit('update:show', false)"
        >取消</com-button
      >
    </div>
    <div class="bottom-image">
      <img src="../../assets/login.png" />
    </div>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
export default {
  components: { comButton },
  data() {
    return {
      songs: null,
    };
  },
  props: {
    list: Array,
    show: Boolean,
    music: Object,
  },
  methods: {
    ok() {
      if (this.songs) {
        this.$emit("ok", this.songs);
      } else {
        this.$message.info("还未选择歌单");
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../style/form";
</style>
<style scoped lang="scss">
.select-container::v-deep {
  //position: fixed;
  //left: 50%;
  //top:50%;
  //transform: translate(-50%,-100%);
  width: 409px;
  background: #fff;
  padding: 0 40px;
  box-sizing: border-box;
  //.el-checkbox__input.is-checked .el-checkbox__inner,
  //.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  //  background-color: #c20c0c;
  //  border-color: #c20c0c;
  //}
  //.el-checkbox__input.is-checked + .el-checkbox__label {
  //  color: #c20c0c;
  //}
}
h4 {
  margin: 15px 0;
}
.info {
  margin-bottom: 10px;
  display: flex;
  .image {
    margin-right: 10px;
    width: 50px;
    img {
      width: 100%;
    }
  }
}
.btns {
  margin: 20px 0;
  display: flex;
  > * {
    margin-right: 10px;
  }
}
.bottom-image {
  margin-top: 20px;
  img {
    width: 100%;
  }
}
</style>
